/* eslint-disable jquery/no-html */
/* eslint-disable jquery/no-find */
/* eslint-disable jquery/no-text */
/* eslint-disable jquery/no-ajax */
import $ from 'jquery';
import { ResizeSensor } from 'css-element-queries';
import { onEvent } from '../util/domutils'; // eslint-disable-line import/named
import initCountrySelector from './header/countrySelector';

export const SELECTORS = {
  HEADER: '.header-sticky',
  BOTTOM_BANNER: '.header-banner-bottom',
  BANNER_CLOSE_BUTTON: '.close-button',
};

const CACHE = {
  RESIZE_SENSOR: null,
  RESIZE_HANDLERS: [],
};

export const EVENTS = {
  UPDATE_HEADER: 'header:update',
};

const handleResizeEvents = (height) => {
  CACHE.RESIZE_HANDLERS.forEach((handler) => {
    handler(height);
  });
};

export const initBannerClose = () => {
  const headerBottomBanner = document.querySelector(SELECTORS.BOTTOM_BANNER);

  if (headerBottomBanner) {
    onEvent(headerBottomBanner, 'click', SELECTORS.BANNER_CLOSE_BUTTON, () => {
      const cookieName = headerBottomBanner.dataset.closeCookie;

      document.cookie = `${cookieName}=true; path=/`;
      headerBottomBanner.remove();
    });
  }
};

export const functions = {
  getHeaderHeight(header = document.querySelector(SELECTORS.HEADER)) {
    if (header) {
      const headerRectangle = header.getBoundingClientRect();
      const headerOffset = headerRectangle.top;
      const headerHeight = header.offsetHeight;
      const headerDisplacement = Math.ceil(headerHeight + headerOffset);

      return headerDisplacement;
    }

    return 0;
  },
  onHeightChange(cb) {
    CACHE.RESIZE_HANDLERS.push(cb);
  },
};

export const initResizeHandler = () => {
  const header = document.querySelector(SELECTORS.HEADER);

  if (header) {
    CACHE.RESIZE_SENSOR = new ResizeSensor(header, () => {
      handleResizeEvents(functions.getHeaderHeight(header));
    });
  }
};

export const initHandleNewsletterValidation = () => {
  $(document).on('submit', '.email-newsletter__form:not(".validated")', function (e) {
    e.preventDefault();
    const $form = $(this);
    const emailField = document.querySelector('.email-newsletter__email-input');
    // eslint-disable-next-line jquery/no-attr
    const requrestUrl = $(this).attr('data-emailvalidate');
    const dataObj = new FormData();
    dataObj.append('email', emailField.value);
    if (emailField) {
      $.ajax({
        url: requrestUrl,
        contentType: false,
        processData: false,
        type: 'post',
        data: dataObj,
        success(data) {
          if (!data.success) {
            $form.find('.invalid-feedback').html(data.error);
            emailField.classList.add('is-invalid');
          } else {
            // eslint-disable-next-line jquery/no-class
            $form.addClass('validated');
            // eslint-disable-next-line jquery/no-trigger
            $form.trigger('submit');
          }
        },
      });
      return false;
    }
    return true;
  });
};

export const pdComponentsJs = () => {
  $(document).ready(() => {
    $('.show-more').on('click', function () {
      // eslint-disable-next-line jquery/no-class
      $('.mission-copy-sign').removeClass('limit-height');
      // eslint-disable-next-line jquery/no-class, jquery/no-parent
      $(this).parent('.readmore-wrapper').removeClass('d-flex').addClass('d-none');
    });
  });
};

export const searchfocus = () => {
  $(document).ready(() => {
    $('.header-search').on('click', () => {
      // eslint-disable-next-line jquery/no-class
      $('.clearfix-self .search-field').focus();
    });
  });
};

export const removeEmoji = () => {
  $(document).on('change keyup', '.removeEmoji', function () {
    // eslint-disable-next-line max-len
    const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    $(this).val($(this).val().replace(regex, '')); // eslint-disable-line jquery/no-val
  });
};

export const initComponents = () => {
  initCountrySelector();
};
