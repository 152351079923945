/* global grecaptcha */

const SELECTORS = {
  RECAPTCHA_ELEMENT: '.g-recaptcha',
};


export default() => {
  const placeholder = document.querySelector(SELECTORS.RECAPTCHA_ELEMENT);

  if (placeholder) {
    const form = placeholder.closest('form');

    if (form) {
      // So you have a function `timeoutFn` which returns a promise eventually resolved, you want to write a function
      // `submitForm` that will call this function n times, waitinng between each call for the returned promise to be
      // resolved. This function will itself return a promise
      // this function returns a promise which is resolved after one second
      const timeoutFn = () => new Promise(resolve => setTimeout(resolve, 500));
      // Recursively call the timeoutFn function until 0 is reached.
      // This will actually create a chain of promises which settle after one second.
      // It also uses the fact that if you return a promise `a` in the `then` handler the returned
      // promise `b` will only settle when `a` is resolved.
      const submitForm = (button) => {
        if (document.querySelector('#g-recaptcha-response').value !== '') {
          button.click();
          return Promise.resolve();
        }
        return timeoutFn().then(() => submitForm(button));
      };
      const buttons = form.querySelectorAll('button');

      buttons.forEach((button) => {
        button.addEventListener('mousedown', (e) => {
          e.preventDefault();

          grecaptcha.ready(() => {
            grecaptcha.execute().then(() => {
              submitForm(button);
            });
          });
        });
      });
    }
  }
};
