import { getJSONData } from '../util/domutils'; // eslint-disable-line import/named
import { handleSizeConversion } from '../../../../scripts/helpers/sizeHelpers';

const SELECTORS = {
  CONVERSION_DETAILS_SOURCE: '[data-size-conversions]',
  LABEL_CONVERSIONS: '[data-convert-label]',
  VALUE_CONVERSIONS: '[data-convert-value]',
};

export default () => {
  const conversionDetailsSource = document.querySelector(SELECTORS.CONVERSION_DETAILS_SOURCE);

  if (conversionDetailsSource) {
    const conversionDetails = getJSONData(conversionDetailsSource, 'sizeConversions', null);

    if (conversionDetails) {
      const labelConversions = document.querySelectorAll(SELECTORS.LABEL_CONVERSIONS);
      const valueConversions = document.querySelectorAll(SELECTORS.VALUE_CONVERSIONS);

      labelConversions.forEach((conversionEl) => {
        let targetText = `${conversionDetails.prefix} ${conversionEl.dataset.convertLabel}`;

        if (conversionEl.dataset.convertLabel.indexOf('{0}') >= 0) {
          targetText = conversionEl.dataset.convertLabel.replace('{0}', conversionDetails.prefix);
        }

        conversionEl.innerText = targetText;
      });

      valueConversions.forEach((conversionEl) => {
        const baseValue = conversionEl.dataset.convertValue;

        conversionEl.innerText = handleSizeConversion(
          baseValue,
          conversionDetails.sizes.default,
          conversionDetails.sizes.current,
          window.beesfra && window.beesfra.config && window.beesfra.config.sizeConversionZeroPad,
        );
      });
    }
  }
};
