import base from '~base';
import { onEvent as on, addClassIfNeeded as addClass } from '../util/domutils'; // eslint-disable-line import/named
import { ADDRESS_FORM_EVENTS } from './addressForm';
import scrollTo from '../util/scrollTo';

const baseSubmit = base.submit;
const baseClearForm = base.functions.clearForm;

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
  let valid = true;
  if (this.checkValidity && !this.checkValidity()) {
    // safari
    valid = false;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
    // eslint-disable-next-line jquery/no-each, jquery/no-find, no-undef
    $(this).find('input, select').each(function () {
      if (!this.validity.valid) {
        // eslint-disable-next-line jquery/no-trigger, no-undef
        $(this).trigger('invalid', this.validity);
      }
    });
  }
  return valid;
}

function scrollToFirstError(parent) {
  setTimeout(() => {
    const errorEl = parent.querySelector('.form-control.is-invalid:not([disabled])');

    if (errorEl) {
      scrollTo(errorEl);
      errorEl.focus();
    }
  }, 1);
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
base.functions.clearForm = (form, resetValidity) => {
  baseClearForm.call(this, form);

  if (!resetValidity) {
    form.querySelectorAll('.form-control').forEach((el) => {
      if (el.required) {
        addClass(el, 'is-valid');
      }
    });
  }
};

base.functions.invalidate = (formGroup, message) => {
  const validatedMessage = message || '';
  const els = formGroup.querySelectorAll('input, select, textarea, .form-control');
  const feedbackContainer = formGroup.querySelector('.invalid-feedback');

  els.forEach((el) => {
    if (el.required) {
      el.classList.toggle('is-invalid', validatedMessage);
      el.classList.toggle('is-valid', !validatedMessage);
    }
  });

  if (feedbackContainer) {
    feedbackContainer.textContent = validatedMessage;
  }

  scrollToFirstError(formGroup);
};

base.submit = () => {
  document.querySelectorAll('form').forEach((form) => {
    form.addEventListener('submit', () => {
      // Dispatch event to show hidden address fields on submit if there are any errors
      document.dispatchEvent(new CustomEvent(ADDRESS_FORM_EVENTS.SUBMIT));

      scrollToFirstError(form);
    });
  });

  baseSubmit();
};

base.buttonClick = () => {
  // Changes the handler of the base event to the overridden clearForm function,
  // so that the valid class is added to the fields and they can be styled appropriately
  on(document, 'click', 'form button[type="submit"], form input[type="submit"]', (e) => {
    // clear all errors when trying to submit the form
    base.functions.clearForm(e.delegateTarget.closest('form'));
  });
};

base.functions.loginValidation = (formGroup, message) => {
  const validatedMessage = message || '';
  const els = formGroup.querySelectorAll('input, select, textarea, .form-control');

  els.forEach((el) => {
    if (el.required) {
      el.classList.toggle('is-invalid', validatedMessage);
      el.classList.toggle('is-valid', !validatedMessage);
    }
  });
};

base.functions.validateForm = (form) => {
  validateForm.call(document.querySelector(form), null);
};

export default base;
