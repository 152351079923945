import $ from 'jquery';
import { isLG } from '../util/domutils';
import throttle from './throttle';

const SELECTORS = {
  HEADER: '.header-sticky',
  PDP: '.rd_PDP',
  PRDDETAIL: 'product-detail',
  NEWPDP: '.new-pdp',
  BREADCRUMB: '.product-detail__top-row-container',
  PRODUCTDETAIL: '.product-detail__details-section',
};

const CONSTANTS = {
  SCROLL_INTERVAL: 150,
};
const PRD_DETAIL = document.getElementsByClassName(SELECTORS.PRDDETAIL);
const PDP = document.querySelector(SELECTORS.PDP);
const NEWPDP = document.querySelector(SELECTORS.NEWPDP);
const header = document.querySelector(SELECTORS.HEADER);

export const stickyHeaderScrollHandle = () => {
  let previousScrollPosition = 0;
  const isScrollingDown = () => {
    let goingDown = false;
    const scrollPosition = window.pageYOffset;
    if (scrollPosition >= header.offsetHeight && scrollPosition > previousScrollPosition) {
      goingDown = true;
    }
    previousScrollPosition = scrollPosition;
    return goingDown;
  };
  const handleScroll = () => {
    if (isScrollingDown()) {
      header.classList.add('scroll-down');
      header.classList.remove('scroll-up');
    } else {
      header.classList.add('scroll-up');
      header.classList.remove('scroll-down');
    }
  };
  const scrollThrottle = throttle(handleScroll, CONSTANTS.SCROLL_INTERVAL);
  if (header) {
    window.addEventListener('scroll', scrollThrottle);
  }
};

export const stickyHeaderScrollHandlePDP = () => {
  const banner = document.querySelector('.header-banner');
  const PDPgallary = document.querySelector('#cld-gallery');
  let previousScrollPosition = 0;
  const isScrollingDown = () => {
    let goingDown = false;
    const scrollPosition = window.pageYOffset;
    if (scrollPosition >= banner.offsetHeight) {
      header.classList.add('scroll-up-top-bar');
    }
    const scrollDownFadeEffect = scrollPosition >= PDPgallary.offsetHeight - PRD_DETAIL[0].dataset.scrolldown;
    if (scrollPosition > previousScrollPosition && scrollDownFadeEffect) {
      goingDown = true;
    }
    const ScrollUpTransparentEffect = PDPgallary.offsetHeight - scrollPosition >= PRD_DETAIL[0].dataset.scrollup;
    if (PDPgallary.offsetHeight > scrollPosition && ScrollUpTransparentEffect && $('.rd_PDP').length === 0) {
      PDP.classList.add('rd_PDP');
    }
    previousScrollPosition = scrollPosition;
    return goingDown;
  };

  const handleHeader = () => {
    const scrollPosition = window.pageYOffset;
    const headerEle = document.querySelector('.compact-header');
    let headerBannerHeight = $('.header-banner').innerHeight();
    const globalBannerHeight = $('#FreeShippingBanner').innerHeight();
    const isGLobaleBanner = $('#FreeShippingBanner').length > 0 || $('.globale-banner').lengh > 0;
    if (isLG()) {
      headerEle.style.top = 0;
    } else if (isGLobaleBanner || $('.new-promo-banner').length > 0) {
      if (isGLobaleBanner) {
        // eslint-disable-next-line no-const-assign
        headerBannerHeight += globalBannerHeight;
      }
      if (scrollPosition > headerBannerHeight) {
        // eslint-disable-next-line jquery/no-css
        $('.compact-header').css('top', 0);
      } else {
        headerEle.style.top = `${headerBannerHeight - $(window).scrollTop()}px`;
      }
    }
  };

  const handleScroll = () => {
    if (NEWPDP && !isLG()) {
      handleHeader();
    }
    if (isScrollingDown()) {
      header.classList.add('scroll-down');
      header.classList.remove('scroll-up');
    } else {
      if (window.pageYOffset >= PDPgallary.offsetHeight) {
        PDP.classList.remove('rd_PDP');
      }
      header.classList.add('scroll-up');
      header.classList.remove('scroll-down');
    }
  };

  if (header) {
    window.addEventListener('scroll', handleScroll);
    if (NEWPDP) {
      window.addEventListener('resize', handleHeader);
    }
    if (('.header-banner-bottom').length > 0) {
      $('.header-banner-bottom').remove();
    }
  }

  if (('.new-promo-banner').length > 0 && !isLG() && (window.pageYOffset <= $('.header-banner').innerHeight())) {
    // eslint-disable-next-line jquery/no-css
    $('.compact-header').css('top', $('.header-banner').innerHeight());
  }
};

function headerInit() {
  const headerBannerHeight = $('.header-banner') && $('.header-banner').innerHeight();
  const isGLobaleBanner = $('#FreeShippingBanner').length > 0 || $('.globale-banner').lengh > 0;
  let globalbanner;
  if ($('#FreeShippingBanner').length > 0) {
    globalbanner = $('#FreeShippingBanner').innerHeight();
  }
  if ($('.globale-banner').length > 0) {
    globalbanner = $('.globale-banner').innerHeight();
  }
  const totalBannerHeight = headerBannerHeight + globalbanner;
  if (isGLobaleBanner > 0) {
    // eslint-disable-next-line jquery/no-css
    if (('.new-promo-banner').length > 0) {
      // eslint-disable-next-line jquery/no-css
      $('.compact-header').css('top', totalBannerHeight);
    } else {
      // eslint-disable-next-line jquery/no-css
      $('.compact-header').css('top', globalbanner);
    }
  } else {
    // eslint-disable-next-line jquery/no-css
    $('.compact-header').css('top', headerBannerHeight);
  }
}

function dockImageCarouselToTop() {
  const headerHeight = header ? header.offsetHeight : 0;
  const productImages = document.querySelector('.product-detail__product-images');
  if (productImages && isLG()) {
    productImages.style.top = `${headerHeight + 15}px`;
    productImages.style.position = 'sticky';
    productImages.style.height = 'fit-content';
  } else {
    productImages.style.position = 'static';
  }
}

// eslint-disable-next-line no-unused-vars
function checkForGEBanner(mutations, observer) {
  // eslint-disable-next-line arrow-parens
  mutations.forEach(mutation => {
    mutation.addedNodes.forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        // Check if the node has the desired ID or class
        if ((node.id === 'FreeShippingBanner' || node.classList.contains('globale-banner')) && !isLG()) {
          headerInit();
        }
      }
    });
  });
}

// Create a MutationObserver instance
if (NEWPDP) {
  const observer = new MutationObserver(checkForGEBanner);
  // Start observing the document's body for child list changes
  observer.observe(document.body, {
    childList: true,
    subtree: false,
  });
}

export default () => {
  if (PRD_DETAIL.length && PDP) {
    stickyHeaderScrollHandlePDP();
  } else {
    stickyHeaderScrollHandle();
  }

  if (PRD_DETAIL.length > 0 && PRD_DETAIL[0].dataset.imagecarouselsticky === 'true' && NEWPDP) {
    dockImageCarouselToTop();
    window.addEventListener('resize', dockImageCarouselToTop);
  }
};
