/* eslint-disable jquery/no-attr */
import $ from 'jquery';
import './intlTelInput';
import { compress, decompress } from 'lzutf8-light';
import scrollTo from '../util/scrollTo';
import { onEvent, refreshAttributes } from '../util/domutils'; // eslint-disable-line import/named
import { loadContent, CONTENT_TYPES } from '../util/fetchutils';
import { removeParamsFromUrl } from '../util/urlutils';

import { EVENTS as HEADER_EVENTS } from './header';

const CONTENT_CACHE = {};

let STATE_CACHE = null;

export const NAV_EVENTS = {
  NAV_BACK: 'nav:back',
  NAV_CONTENT: 'nav:content',
};

const getUpdateUrl = (triggerUrl, useFullUrl = false, paramsBlacklist = []) => {
  let target = triggerUrl || window.location.href;

  if (!useFullUrl) {
    const loc = window.location;
    const base = `${loc.protocol}//${loc.hostname}${loc.pathname}`;
    const triggerUrlParts = target.split('?');

    if (triggerUrlParts.length > 1) {
      target = `${base}?${triggerUrlParts[1]}`;
    }
  }

  return removeParamsFromUrl(target, ['navAjax', ...paramsBlacklist]);
};

/**
 * Pushes a history state on the stack
 *
 * @param {string}  targetSelector Selector for the element updated during the current step
 * @param {string}  content The content corresponding to the current step
 * @param {boolean} [scrollTarget] If provided, the window will be scrolled to that element on restore
 * @param {boolean} [useFullUrl] If truthy, the passed in URL will replace the location, instead of just the QS
 * @param {string}  [url=window.location.href] The update URL used during the current step
 */
const addHistoryStep = (historyMethod, targetSelector, content, scrollTarget, paramsBlacklist, useFullUrl, url) => {
  if (targetSelector && content) {
    const compressedContent = compress(content);
    const updateUrl = getUpdateUrl(url, useFullUrl, paramsBlacklist);
    const stateObj = {
      targetSelector, content: updateUrl, scrollTarget,
    };

    CONTENT_CACHE[updateUrl] = compressedContent;
    window.history[historyMethod](stateObj, document.title, updateUrl);
    STATE_CACHE = stateObj;
  }
};

const pushHistoryStep = (...args) => {
  addHistoryStep('pushState', ...args);
};

const replaceHistoryStep = (...args) => {
  addHistoryStep('replaceState', ...args);
};

/**
 * Attaches a popstate event listener, which handles back navigation
 */
const initHistoryHandling = () => {
  window.onpopstate = (e) => {
    if (e.state && e.state.targetSelector && e.state.content) {
      const target = document.querySelector(e.state.targetSelector);

      if (target) {
        const content = CONTENT_CACHE[e.state.content];

        if (content) {
          target.innerHTML = decompress(content);
          delete CONTENT_CACHE[e.state.content];
        }
      }

      if (e.state.scrollTarget) {
        scrollTo(e.state.scrollTarget);
      }

      document.dispatchEvent(new CustomEvent(NAV_EVENTS.NAV_BACK));
    }
  };
};

const populateFormValuesInCurrentStep = (paramsBlacklist) => {
  const stateObj = window.history.state || STATE_CACHE;
  const { targetSelector, scrollTarget } = stateObj;

  if (targetSelector) {
    const target = document.querySelector(targetSelector);

    if (target) {
      refreshAttributes(targetSelector);
      replaceHistoryStep(targetSelector, target.innerHTML, scrollTarget, paramsBlacklist);
    }
  }
};

/**
 * Executes a nav update over AJAX
 *
 * @param {Element} trigger           The element that triggered the update
 * @param {string}  targetSelector    Selector for the update target
 * @param {string}  [contentKey]      If provided, and the response is JSON, this field will be returned from it
 * @param {boolean} [scrollTarget]    If provided, the window will be scrolled to that element after the update
 * @param {boolean} [append=false]    If true, the fetched content will be appended to the target
 * @param {RenderCallback} [renderer] If provided will be called instead of the standard renderer
 * @param {UpdateCallback} [callback] If provided will be called after the target is updated
 */
const handleUpdate = async (trigger, config) => {
  const {
    targetSelector,
    contentKey,
    scrollTarget,
    ignoreScroll,
    paramsBlacklist,
    append = false,
    renderer,
    callback,
    urlInputSelector,
  } = config;

  const { url, type, content } = await loadContent(trigger, null, { navAjax: true });

  let responseContent;
  if (type === CONTENT_TYPES.JSON && content) {
    if (contentKey) {
      responseContent = content[contentKey];
    } else {
      responseContent = content;
    }
  } else if (type === CONTENT_TYPES.TEXT) {
    responseContent = content;
  }

  if (responseContent) {
    let overrideUrl = null;
    let useFullUrl = false;
    const target = document.querySelector(targetSelector);

    // This ensures that any values the customer entered will get restored on back navigation
    populateFormValuesInCurrentStep(paramsBlacklist);

    if (target) {
      if (typeof renderer === 'function') {
        await renderer(content, append);
        const $dialCode = $('#dialCode');
        if ($dialCode) {
          const allowedCountries = $dialCode.attr('allowedLocales');
          const defaultCountryCode = $dialCode.attr('defaultCountryCode');
          const $onlyCountries = allowedCountries ? allowedCountries.split(',') : '';
          $('#dialCode').intlTelInput({
            allowDropdown: true,
            initialCountry: defaultCountryCode,
            formatOnDisplay: true,
            separateDialCode: true,
            page: 'checkout-page',
            onlyCountries: $onlyCountries,
            dropdownContainer: $('.country-dial-code'),
          });
        }
      } else if (append) {
        target.insertAdjacentHTML('beforeend', responseContent);
      } else {
        target.innerHTML = responseContent;
      }

      const paymentForm = document.querySelector(ignoreScroll);
      if (scrollTarget && paymentForm == null) {
        scrollTo(scrollTarget);
      }

      if (callback) {
        callback(trigger, target, responseContent);
      }

      if (urlInputSelector) {
        const urlInput = document.querySelector(urlInputSelector);

        if (urlInput) {
          useFullUrl = true;
          overrideUrl = urlInput.value.trim();
        }
      }

      pushHistoryStep(targetSelector, target.innerHTML, scrollTarget, paramsBlacklist, useFullUrl, overrideUrl || url);

      setTimeout(() => {
        document.dispatchEvent(new CustomEvent(NAV_EVENTS.NAV_CONTENT));
        document.dispatchEvent(new CustomEvent(HEADER_EVENTS.UPDATE_HEADER));
      }, 10);
    }
  }
};

/**
 * Initializes the navigation logic by persisting the content of the element
 * retrieved with the provided parent selector in an initial history state,
 * which is used as an indicator for the start of the history chain,
 * right before the content for the first client-side navigation is rendered
 *
 * @param {string} targetSelector Selector for the root element
 */
const initNav = (targetSelector) => {
  const target = document.querySelector(targetSelector);

  if (target) {
    const content = target.innerHTML;

    replaceHistoryStep(targetSelector, content);
  }
};

/**
 * Attaches listeners to the configured AJAX update triggers
 *
 * @param {Object[]} config Config objects for the navigation logic
 */
const initTriggers = (configs) => {
  configs.forEach((config) => {
    const { triggerSelector, targetSelector } = config;

    if (targetSelector) {
      (config.interactionEvents || ['submit', 'click']).forEach((evt) => {
        onEvent(document, evt, triggerSelector, (e) => {
          const hasAction = (e.delegateTarget.href || e.delegateTarget.dataset.action);
          if ((evt === 'click' && hasAction) || (evt === 'submit' && e.delegateTarget.action)) {
            e.preventDefault();

            handleUpdate(e.delegateTarget, config);
          }
        });
      });

      onEvent(document, 'change', triggerSelector, (e) => {
        if (e.delegateTarget.tagName.toLowerCase() === 'select') {
          const selectedOption = e.delegateTarget.options[e.delegateTarget.selectedIndex];

          if (selectedOption && selectedOption.value) {
            const { value } = selectedOption;
            const hasUrl = value.startsWith('http') || value.startsWith('/');

            if (hasUrl) {
              selectedOption.dataset.action = value;

              handleUpdate(selectedOption, config);
            }
          }
        }
      });
    }
  });
};

/**
 * Callback for a nav update operation
 *
 * @callback UpdateCallback
 * @param {Element} trigger The element that triggered the update
 * @param {Element} target The updated target
 */
/**
 * Callback for the replaces that standard render process
 *
 * @callback RenderCallback
 * @param {Response} response The AJAX response to render
 */
/**
 * Module that handles updating page components over AJAX
 * and updating the URL and history to match those changes
 *
 * @param {string} navParentSelector Selector to use to populate the initial state of the page
 * @param {Object[]} config Config objects for the navigation logic
 * @param {string} config[].triggerSelector Selector for the navigation trigger element(s)
 * @param {string} config[].targetSelector Selector for the element to update
 * @param {boolean} [config[].scrollTarget] If provided, the window will be scrolled to that element after the update
 * @param {boolean} [config[].append=false] If true, the fetched content will be appended to the target
 * @param {string[]} [config[].interactionEvents] Can replace the default ['click', 'submit'] as navigation triggers
 * @param {RenderCallback} [config[].renderer] If provided will be called instead of the standard renderer
 * @param {UpdateCallback} [config[].callback] If provided will be called after the target is updated
 */
export const clientSideNav = (navParentSelector, config) => {
  initNav(navParentSelector);
  initTriggers(config);
  initHistoryHandling();
};
